<template>
  <div >
    <div v-if='!isShowLog'>
      <SEARCH :searchChange='onCallbackSearch' :list="searchArr"></SEARCH>
      <TABS :tabChange='onCallbackTab' :list="tabsArr"></TABS>
      <TABLE :switchChange='onCallbackSwitch' :selectChange='onCallbackSelect' :buttonChange='onCallbackButton' :list="tableArr" :data="dataArr"></TABLE>
      <PAGE :pageChange='onCallbackPage' :limitChange='onCallbackLimit' :count="count"></PAGE>

      <!-- 添加 or 编辑设备 -->
      <add-device :loadChange='_loadData' ref="addD"></add-device>

      <!-- 以下是子组件 -->
      <show-live ref='showLive'></show-live>
      <!--  -->
      <show-location @close="() => showType = ''" :id='this.showTypeMemID' :isSearch='true' v-if="showType === 'showLocation'"></show-location>
      <show-location @close="() => showType = ''" :id='this.showTypeMemID' :isSearch='false' v-if="showType === 'showLocationHd'"></show-location>

      <show-video @close="() => showType = ''" :id='this.showTypeID' :isHD='false' v-if="showType === 'showVideo'"></show-video>
      <show-video @close="() => showType = ''" :id='this.showTypeID' :isHD='true' v-if="showType === 'showVideoHD'"></show-video>

      <warning-value @close="() => showType = ''" :id='this.showTypeID' v-if="showType === 'warningValue'"></warning-value>

      <gas-warning-value @close="() => showType = ''" :id='this.showTypeID' v-if="showType === 'gasWarningValue'"></gas-warning-value>

      <heart-b-data @close="() => showType = ''" :id='this.showTypeMemID' v-if="showType === 'heartBData'"></heart-b-data>

      <gas-data @close="() => showType = ''" :id='this.showTypeMemID' v-if="showType === 'gasData'"></gas-data>

      <sur-data @close="() => showType = ''" :id='this.showTypeMemID' v-if="showType === 'surData'"></sur-data>

      <tem-data @close="() => showType = ''" :id='this.showTypeMemID' v-if="showType === 'temData'"></tem-data>
      <!-- <select-point></select-point> -->
    </div>
    <div v-else>
      <NAVBAR :backChange='onCallbackBack' title="识别记录"></NAVBAR>
      <face-log :id="showTypeMemID"></face-log>
    </div>
    <!--  -->
  </div>
</template>

<script>
import NAVBAR from '@/components/navbar' // 头部
import SEARCH from '@/components/search' // 顶部搜索
import TABS from '@/components/tabs' // 横向 button
import TABLE from '@/components/table' // 列表
import PAGE from '@/components/page' // 分页
import addDevice from './components/addDevice' // 添加设备
import { getDevice, getDeviceDetail, delDevice, updDeviceStatus } from '@/api/device'
import showLive from '@/components/device/showLive' // 单兵播放
import showLocation from '@/components/device/showLocation' // 定位轨迹
import showVideo from '@/components/device/showVideo' // 摄像头播放
import warningValue from '@/components/device/warningValue' // 手环预警值
import gasWarningValue from '@/components/device/gasWarningValue' // 气体预警值
import heartBData from '@/components/device/heartBData' // 心率血压
import faceLog from '@/components/device/faceLog' // 识别记录
import gasData from '@/components/device/gasData' // 气体实时数据
import surData from '@/components/device/surData' // 环境实时数据
import temData from '@/components/device/temData' // 体温实时数据
// import selectPoint from '@/components/selectPoint'
export default {
  name: 'DeviceList',
  components: {
    // selectPoint,
    NAVBAR,
    SEARCH,
    TABS,
    TABLE,
    PAGE,
    addDevice,
    showLive,
    showLocation,
    showVideo,
    warningValue,
    gasWarningValue,
    heartBData,
    faceLog,
    gasData,
    surData,
    temData,
  },
  data() {
    return {
      searchArr: [
        {
          name: '设备名称',
          type: 'input',
          options: [],
          key: 'projectName',
        },
        {
          name: '设备类型',
          type: 'picker',
          label: 'name',
          value: 'id',
          options: [
            { id: '', name: '全部' },
            { id: 1, name: '摄像头' },
            { id: 2, name: '单兵' },
            { id: 3, name: '手环' },
            { id: 4, name: '气体检测' },
            { id: 5, name: '环境检测' },
            { id: 6, name: '人脸设备' },
          ],
          key: 'projectType',
        },
        {
          name: '设备编号',
          type: 'input',
          key: 'id',
        },
      ], // 搜索列表
      tabsArr: [
        {
          type: 'primary',
          name: '添加设备',
          func: 'openAddDevice',
          isLoading: false,
        },
        {
          type: 'danger',
          name: '批量删除',
          func: 'removeDevice',
          isLoading: false,

        },
      ], // 标签列表
      tableArr: [
        {
          prop: 'unit_type',
          label: '设备类型',
          width: '120',
        },
        {
          prop: 'unit_name',
          label: '设备名称',
          width: '120',
        },
        {
          prop: 'unit_member',
          label: '设备编号',
          width: '100',
        },
        {
          prop: 'unit_camera',
          label: '设备类别',
          width: '100',
        },
        {
          prop: 'unit_code',
          label: '验证码',
          width: '100',
        },
        {
          prop: 'unit_aisle',
          label: '通道号',
          width: '120',
        },
        {
          prop: 'online',
          label: '在线状态',
          width: '120',
          type: 'tag',
        },
        {
          prop: 'unit_status',
          label: '设备状态',
          width: '120',
          type: 'switch',
        },
        {
          prop: 'unit_addtime',
          label: '创建时间',
          width: '170',
        },
        {
          prop: 'auth',
          label: '相关数据',
          type: 'other',
        },
        {
          prop: '',
          label: '操作',
          width: '170',
          type: 'but',
        },
      ], // 表头列表
      dataArr: [], // 数据列表
      butnArr: [
        {
          type: '',
          name: '编辑',
          func: 'editAddDevice',
        },
        {
          type: 'danger',
          name: '删除',
          func: 'removeDevice',
        },
      ], // 数据-操作列表
      count: 0, // 总页数
      formData: {
        page: 1,
        limit: 10,
        unit_type: '',
        unit_name: '',
        unit_member: '',
      },
      ids: '', // 要删除的id
      tabsIndex: 0, // tabs 按钮的索引
      other: {
        1: [
          {
            type: '',
            name: '标清播放',
            func: 'open',
            component: 'showVideo',
          },
          {
            type: '',
            name: '高清播放',
            func: 'open',
            component: 'showVideoHD',
          },
        ], // 摄像头
        2: [
          {
            type: '',
            name: '现场实景',
            func: 'open',
            component: 'showLive',
          },
          {
            type: '',
            name: '实时对讲',
            func: 'open',
            component: 'showLiveTalk',
          },
          {
            type: '',
            name: '定位轨迹',
            func: 'open',
            component: 'showLocation',
          },
        ], // 单兵
        3: [
          {
            type: '',
            name: '心率体征',
            func: 'open',
            component: 'heartBData',
          },
          {
            type: '',
            name: '体温',
            func: 'open',
            component: 'temData',
          },
          {
            type: '',
            name: '定位',
            func: 'open',
            component: 'showLocationHd',
          },
          {
            type: '',
            name: '预警值',
            func: 'open',
            component: 'warningValue',
          },
        ], // 手环
        4: [
          {
            type: '',
            name: '实时数据',
            func: 'open',
            component: 'gasData',
          },
          {
            type: '',
            name: '预警值',
            func: 'open',
            component: 'gasWarningValue',
          },
        ], // 气体
        5: [
          {
            type: '',
            name: '实时数据',
            func: 'open',
            component: 'surData',
          },
          // {
          //   type: '',
          //   name: '预警值',
          //   func: 'editAddDevice',
          // },
        ], // 环境
        6: [
          {
            type: '',
            name: '识别记录',
            func: 'open',
            component: 'faceLog',
          },
        ], // 人脸
      },
      showType: '', // 打开的子组件
      showTypeID: '', // id
      showTypeMemID: '', // 设备号
      isShowLog: false, // 人脸识别
    }
  },
  created() {},
  mounted() {
    this._loadData()
  },
  methods: {
    _loadData: function () {
      this.formData.unit_id = this.$store.getters.USER_ID
      getDevice(this.formData).then((res) => {
        const { result } = res

        this.count = result.count
        result.data.forEach((item) => {
          item.buttons = this.butnArr
          item.unit_status = {
            status: item.unit_status === 1,
            disabled: false,
            func: 'changeStatus',
          }
          // eslint-disable-next-line eqeqeq
          if (item.unit_type_list !== 4) {
            item.online = {
              type: item.online === 1 ? 'success' : 'danger',
              name: item.online === 1 ? '在线' : '离线',
            }
          } else {
            item.online = {
              type: '',
              name: '',
            }
          }
          item.other = this.other[item.unit_type_list]
        })
        this.dataArr = result.data
      })
    },

    // 打开新增设备弹窗
    openAddDevice: function() {
      this.loading()
      this.$refs.addD.open()
    },

    changeStatus: function({ id, unit_status: st }) {
      const data = {
        id,
        status: st.status ? 1 : 0,
      }

      updDeviceStatus(data).then(res => {
        this.$toast(res.message)
        this._loadData()
      })
    },

    open: function(item, type) {
      console.log(item, '打开全部列表')
      if (type === 'showLive') {
        this.$refs.showLive.open({ name: item.unit_name, num: item.unit_member, isShowTalk: false })
      } else if (type === 'showLiveTalk') {
        this.$refs.showLive.open({ name: item.unit_name, num: item.unit_member, isShowTalk: true })
      } else if (type === 'faceLog') {
        this.isShowLog = true
        this.showTypeMemID = item.unit_member
        this.$store.commit('SET_BC', false)
      } else {
        this.showTypeMemID = item.unit_member
        this.showTypeID = item.id
        this.showType = type
        console.log(this.showType)
      }
    },

    // 关闭统计、添加
    onCallbackBack: function() {
      this.$store.commit('SET_BC', true)
      this.isShowLog = false
      // this._loadData()
    },

    // 编辑
    editAddDevice: function() {
      getDeviceDetail(this.ids).then(res => {
        this.$refs.addD.open(res.result.unit)
      })
    },

    // 删除设备
    removeDevice: function() {
      if (!this.ids) {
        this.$toast('请选择要删除的设备')
        this.loading()
        return false
      }
      delDevice(this.ids).then(res => {
        this.$toast(res.message)
        this._loadData()
        this.loading()
      })
    },

    // 收到搜索回调
    onCallbackSearch: function(data) {
      const { id, projectName, projectType } = data
      this.formData.unit_member = id
      this.formData.unit_name = projectName
      this.formData.unit_type = projectType
      this._loadData()
    },
    // 收到 tabs 回调
    onCallbackTab: function({ item, index }) {
      this.loading(index)
      this[item.func]()
    },

    // 打开或关闭tabs 按钮loading
    loading: function(index) {
      index && (this.tabsIndex = index)
      this.tabsArr[this.tabsIndex].isLoading = !this.tabsArr[this.tabsIndex].isLoading
    },

    // 收到 表格内 switch 方法回调
    onCallbackSwitch: function(item, func) {
      this[func](item)
    },

    // 收到 选择 回调
    onCallbackSelect: function(ids) {
      this.ids = ids
    },
    // 收到 表格 内方法 回调
    onCallbackButton: function(item, func, type) {
      this.ids = item.id
      this[func](item, type)
    },
    // 收到改变页码回调
    onCallbackPage: function(page) {
      this.formData.page = page
      this._loadData()
    },
    // 收到改变一页数量回调
    onCallbackLimit: function(limit) {
      this.formData.limit = limit
      this._loadData()
    },
  },
}
</script>

<style lang="stylus" scoped></style>
