<template>
  <el-dialog
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="true"
    :destroy-on-close='true'
    @close='onCallbackClose'
    width="45%"
    :title="`现场实景`"
    :visible.sync="isShow"
  >
    <div id="cmsv6flash"></div>

    <div v-show="obj.isShowTalk" slot="footer" class="dialog-footer butt">
      <el-button style="margin-right: 20px" type="primary" @click="start">{{ but }}</el-button>
      <el-button type="warning" @click="done">结束对讲</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { getDevLive } from '@/api/common'
import config from '@/utils/config'
export default {
  name: 'showLive',
  data () {
    return {
      isShow: false,
      obj: {},
      but: '发起对讲',
    }
  },
  created() {

  },
  mounted () {

  },
  methods: {
    open: function(data) {
      this.isShow = true
      this.obj = data
      getDevLive(data.num).then(res => {
        const { result } = res
        this.obj.id = result.sessionId
        this.initPlayerExample()
      })
    },
    initPlayerExample: function () {
      var params = {
        lang: 'zh-cn',
      }
      // 初始化flash

      // eslint-disable-next-line no-undef
      ttxVideoAll.init('cmsv6flash', 400, 400, params, 'auto')
      this.initFlash()
    },
    initFlash: function () {
      // eslint-disable-next-line no-undef
      // console.log(typeof swfobject.getObjectById('cmsv6flash').setWindowNum)
      if (
        typeof swfobject === 'undefined' ||
        // eslint-disable-next-line no-undef
        swfobject.getObjectById('cmsv6flash') == null ||
        // eslint-disable-next-line no-undef
        typeof swfobject.getObjectById('cmsv6flash').setWindowNum ===
          'undefined'
      ) {
        setTimeout(this.initFlash, 50)
      } else {
        // 设置视频插件的语言('playerType=flash'时生效)
        // eslint-disable-next-line no-undef
        swfobject.getObjectById('cmsv6flash').setLanguage('cn.xml')
        // 先将全部窗口创建好
        // eslint-disable-next-line no-undef
        swfobject.getObjectById('cmsv6flash').setWindowNum(1)
        // 再配置当前显示的窗口数目
        // eslint-disable-next-line no-undef
        swfobject.getObjectById('cmsv6flash').setWindowNum(1)

        // 设置视频插件的服务器ip和登录服务器端口
        // eslint-disable-next-line no-undef
        swfobject
          .getObjectById('cmsv6flash')
          .setServerInfo(config.IP, config.PORT)

        this.playVideo()
      }
    },

    // 播放
    playVideo: function (item) {
      // eslint-disable-next-line no-undef
      swfobject.getObjectById('cmsv6flash').setVideoInfo(0, this.obj.name)

      // eslint-disable-next-line no-undef
      swfobject
        .getObjectById('cmsv6flash')
        .startVideo(0, this.obj.id, this.obj.num, 0, 0, true)
    },

    // 对讲
    start: function() {
      // eslint-disable-next-line no-undef
      // swfobject.getObjectById('cmsv6flash').setTalkParam(0)
      // eslint-disable-next-line no-undef
      const a = swfobject
        .getObjectById('cmsv6flash')
        .startTalkback(this.obj.id, this.obj.num, 0, config.IP, config.PORT)
      console.log(a, '这hi是：：：：')
      this.but = '对讲中...'
    },

    // 结束对讲
    done: function() {
      // eslint-disable-next-line no-undef
      swfobject.getObjectById('cmsv6flash').stopTalkback()
      this.but = '发起对讲'
    },

    // 关闭
    onCallbackClose: function() {
      this.isShow = false

      // eslint-disable-next-line no-undef
      swfobject.getObjectById('cmsv6flash').stopVideo(0)
      // eslint-disable-next-line no-undef
      swfobject.getObjectById('cmsv6flash').reSetVideo(0)
      this.but = '发起对讲'
      this.obj = {}
    },
  },
}
</script>

<style lang="stylus" scoped>
#cmsv6flash{
  width 100% !important;
}
.butt{
  display flex;
  align-items center;
  justify-content center;
  margin-top 20px;
}
</style>
