<template>
  <el-dialog
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :destroy-on-close="true"
    :show-close="true"
    @close="onCallbackClose"
    width="48%"
    title="心率血压"
    :visible.sync="isShow"
    append-to-body
  >
    <el-radio-group v-model="selectIndex" style="margin-bottom: 30px">
      <el-radio-button
        v-for="(item, index) in tagArr"
        :key="index"
        :label="index"
        >{{ item.name }}</el-radio-button
      >
    </el-radio-group>
    <div class="real-time-title">
      <span></span>
      <span>{{ tagArr[selectIndex].name }}</span>
    </div>

    <div ref="showLineContent" class="line-content"></div>
    <el-table
      ref="multipleTable"
      :data="data"
      tooltip-effect="dark"
      style="width: 100%"
      height="198"
      border
    >
      <!-- <template v-for="(item, index) in list"> -->
      <el-table-column
        :key="index"
        label="时间"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <!-- <div>1</div> -->
          <div>{{ scope.row.create_time }}</div>
        </template>
      </el-table-column>
       <el-table-column
       :key="index"
        label="高压/低压"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <div>{{ scope.row.sdp }} / {{ scope.row.dbp }}</div>
        </template>
      </el-table-column>
       <el-table-column
       :key="index"
        label="心率"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <div>{{ scope.row.heartRate }}</div>
        </template>
      </el-table-column>
      <!-- </template> -->
    </el-table>
    <PAGE :pageChange='onCallbackPage' :limitChange='onCallbackLimit' :count="count"></PAGE>

  </el-dialog>
</template>

<script>
import PAGE from '@/components/page' // 分页
import { getHeartB } from '@/api/device'

var echarts = require('echarts/lib/echarts')
require('echarts/lib/chart/line')
require('echarts/lib/component/tooltip')
require('echarts/lib/component/title')
require('echarts/lib/component/legend')
require('echarts/lib/coord/cartesian/Grid')
require('echarts/lib/coord/cartesian/Axis2D')
export default {
  name: 'heartBData',
  components: {
    PAGE,
  },
  props: {
    id: {
      type: [String, Number],
      default() {
        return ''
      },
    },
  },
  data() {
    return {
      isShow: true,
      tagArr: [{ name: '心率' }, { name: '血压' }],
      selectIndex: 0,
      data: [],
      list: [],
      formData: {
        page: 1,
        limit: 10,
        unit_member: '',
      },
      count: 10,
    }
  },
  watch: {
    selectIndex: function() {
      this._loadData()
    },
  },
  mounted() {
    this._loadData()
  },
  methods: {
    _loadData: function () {
      this.formData.unit_member = this.id
      getHeartB(this.formData).then(res => {
        const { result } = res
        this.count = result.count
        const time = []
        const data = []
        const lowData = []
        this.data = result.list
        result.list.forEach(s => {
          const t = s.create_time.split(' ')[1].split(':')
          time.push(`${t[0]}:${t[1]}`)
          if (this.selectIndex) {
            data.push(s.sdp)
            lowData.push(s.dbp)
          } else {
            data.push(s.heartRate)
          }
        })

        // time.reverse()
        // data.reverse()
        // lowData.reverse()
        this.setOption(time, data, lowData)
      })
    },
    setOption: function (time, data, lowData) {
      const self = this
      const option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
          formatter: function (params) {
            var result = ''
            const value = params[0].axisValue
            params.forEach(function (item) {
              var dotHtml = `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${item.color}"></span>`
              result +=
                '</br>' +
                dotHtml +
                item.data +
                ` ${self.selectIndex ? 'BMP' : 'MMHG'}`
            })
            return value + result
          },
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: time,
          axisLine: {
            lineStyle: {
              type: 'solid',
              color: '#ebebeb',
              width: '2',
            },
          },
          axisLabel: {
            textStyle: {
              color: '#88949E',
              fontSize: 12,
            },
          },
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: true,
            lineStyle: {
              color: ['#ebebeb'],
              width: 1,
              type: 'solid',
            },
          },
          axisLine: {
            lineStyle: {
              type: 'solid',
              color: 'red',
              width: '2',
            },
          },
          axisLabel: {
            textStyle: {
              color: '#88949E',
              fontSize: 12,
            },
            formatter: function (value) {
              return value + `${self.selectIndex ? 'BMP' : 'MMHG'}`
            },
          },
        },
        color: ['red'],
        series: [
          {
            data: data,
            color: '#00E5FF',
            type: 'line',
            lineStyle: {
              normal: {
                color: '#00E5FF',
                width: 2,
              },
            },
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: '#1ABEAE', // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: 'rgba(26, 190, 174, 0.09)', // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
          },
          {
            data: lowData,
            type: 'line',
            color: '#31FFAB',
            lineStyle: {
              normal: {
                color: '#31FFAB',
                width: 2,
              },
            },
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(47,244,164,0.5)', // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: 'rgba(47,244,164,0)', // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
          },
        ],
      }
      setTimeout(() => {
        var myChart = echarts.init(this.$refs.showLineContent)
        myChart.setOption(option)
      }, 500)
    },
    // 关闭
    onCallbackClose: function () {
      this.isShow = false
      this.$emit('close')
    },
    // 收到改变页码回调
    onCallbackPage: function(page) {
      this.formData.page = page
      this._loadData()
    },
    // 收到改变一页数量回调
    onCallbackLimit: function(limit) {
      this.formData.limit = limit
      this._loadData()
    },
  },
}
</script>

<style lang="stylus" scoped>
.line-content {
  width: 100%;
  height: 200px;
}

.real-time-title {
  height: 20px;
  margin-top: 20px;

  span {
    &:nth-of-type(1) {
      display: inline-block;
      width: 10px;
      height: 10px;
      margin-right: 10px;
      margin-left: 5px;
      background: #29D9C8;
      box-shadow: 0px 0px 6px #1EDECB;
      border-radius: 50%;
    }

    &:nth-of-type(2) {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #515A6E;
    }
  }
}
</style>
