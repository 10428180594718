<template>
  <el-dialog
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :destroy-on-close="true"
    :show-close="false"
    width="50%"
    title="预警值"
    :visible.sync="isShow"
  >
    <div class="all-input">
      <div class="item-input">
        <div class="label"><span class="red">*</span>心率正常值：</div>
        <span class="tag">最小值</span>
        <el-input-number
          class="mini z-tag"
          v-model="formData.heart_min"
          controls-position="right"
          :min="0"
        ></el-input-number>
      </div>
      <div style="margin-left: -7%" class="item-input">
        <div class="label"></div>
        <span class="tag">最大值</span>
        <el-input-number
          class="mini z-tag"
          v-model="formData.heart_max"
          controls-position="right"
          :min="0"
        ></el-input-number>
      </div>
      <div class="item-input">
        <div class="label"><span class="red">*</span>低压正常值：</div>
        <span class="tag">最小值</span>
        <el-input-number
          class="mini z-tag"
          v-model="formData.dbp_min"
          controls-position="right"
          :min="0"
        ></el-input-number>
      </div>
      <div style="margin-left: -7%" class="item-input">
        <div class="label"></div>
        <span class="tag">最大值</span>
        <el-input-number
          class="mini z-tag"
          v-model="formData.dbp_max"
          controls-position="right"
          :min="0"
        ></el-input-number>
      </div>
      <div class="item-input">
        <div class="label"><span class="red">*</span>高压正常值：</div>
        <span class="tag">最小值</span>
        <el-input-number
          class="mini z-tag"
          v-model="formData.sdp_min"
          controls-position="right"
          :min="0"
        ></el-input-number>
      </div>
      <div style="margin-left: -7%" class="item-input">
        <div class="label"></div>
        <span class="tag">最大值</span>
        <el-input-number
          class="mini z-tag"
          v-model="formData.sdp_max"
          controls-position="right"
          :min="0"
        ></el-input-number>
      </div>
      <div class="item-input">
        <div class="label"><span class="red">*</span>体温正常值：</div>
        <span class="tag">最小值</span>
        <el-input-number
          class="mini z-tag"
          v-model="formData.temp_min"
          controls-position="right"
          :min="0"
        ></el-input-number>
      </div>
      <div style="margin-left: -7%" class="item-input">
        <div class="label"></div>
        <span class="tag">最大值</span>
        <el-input-number
          class="mini z-tag"
          v-model="formData.temp_max"
          controls-position="right"
          :min="0"
        ></el-input-number>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit">确 定</el-button>
      <el-button @click="close">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { getHandWarn, updHandWarn } from '@/api/device'
export default {
  name: 'warningValue',
  props: {
    id: {
      type: [String, Number],
      default() {
        return ''
      },
    },
  },
  data() {
    return {
      isShow: true, // 是否打开弹窗
      options: [],
      formData: {
        heart_max: '',
        heart_min: '',
        dbp_max: '',
        dbp_min: '',
        sdp_max: '',
        sdp_min: '',
        temp_max: '',
        temp_min: '',
        unit_id: '',
      },
      toastData: {
        heart_max: '请输入心率最大值',
        heart_min: '请输入心率最小值',
        dbp_max: '请输入低压最大值',
        dbp_min: '请输入低压最小值',
        sdp_max: '请输入高压最大值',
        sdp_min: '请输入高压最小值',
        temp_max: '请输入体温最大值',
        temp_min: '请输入体温最小值',
      },
    }
  },
  created() {
    this._loadData()
  },
  methods: {
    _loadData: function() {
      getHandWarn(this.id).then(res => {
        const { result } = res
        delete result.id
        result.data && (this.formData = result.data)
      })
    },
    close: function (isLoad) {
      this.isShow = false
      this.$emit('close')
    },
    submit: function () {
      // for (const key in this.toastData) {
      //   if (!this.formData[key]) {
      //     this.$toast(this.toastData[key])
      //     return false
      //   }
      // }
      this.formData.unit_id = this.id
      updHandWarn(this.formData).then(res => {
        this.$toast(res.message)

        if (res.status === 200) {
          this.$emit('close')
        }
      })
    },
  },
}
</script>

<style lang="stylus" scoped>
.tag {
  // border-radius: 4px;
  border: 1px solid #DCDFE6;
  box-sizing: border-box;
  height: 40px;
  line-height: 40px;
  border-right: none;
  padding: 0 10px;
  border-radius: 4px 0 0 4px;
  margin-top: 1px;
  white-space nowrap;
  overflow hidden
  text-overflow ellipsis
}
.label {
  white-space nowrap;
  overflow hidden
  text-overflow ellipsis
}
</style>
