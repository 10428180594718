<template>
  <el-dialog
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :destroy-on-close="true"
    :show-close="false"
    width="45%"
    :title="`${isEdit ? '编辑' : '添加'}设备`"
    :visible.sync="isShow"
  >
    <div class="all-input">
      <div class="item-input">
        <div class="label"><span class="red">*</span>设备类型：</div>
        <el-select
          class="mini"
          @change='getChangeType'
          v-model="formData.unit_type"
          placeholder="请选择设备类型"
        >
          <el-option
            v-for="item in typeOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="item-input">
        <div class="label"><span class="red">*</span>设备编号：</div>
        <el-input
          v-model="formData.unit_member"
          placeholder="请输入设备编号"
          class="mini"
        ></el-input>
      </div>
      <div class="item-input">
        <div class="label"><span class="red">*</span>设备名称：</div>
        <el-input placeholder="请输入设备名称" v-model="formData.unit_name" class="mini"></el-input>
      </div>
      <div v-show="formData.unit_type === 1" class="item-input">
        <div class="label"><span class="red">*</span>验证码：</div>
        <el-input placeholder="请输入验证码" v-model="formData.unit_code" class="mini"></el-input>
      </div>
      <div v-show="options.length" class="item-input">
        <div class="label"><span class="red">*</span>设备类别：</div>
        <el-select
          class="mini"
          v-model="formData.unit_camera"
          placeholder="请选择设备类别"
        >
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.info"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div v-if="formData.unit_type === 1" class="item-input">
        <div class="label"><span class="red">*</span>通道号：</div>
        <el-input-number
          class="mini"
          v-model="formData.unit_aisle"
          controls-position="right"
        ></el-input-number>
      </div>
      <div class="item-input">
        <div class="label">设备状态：</div>
        <div style="line-height: 38px" class="mini">
          <el-radio v-model="formData.unit_status" label="1">显示</el-radio>
          <el-radio v-model="formData.unit_status" label="0">隐藏</el-radio>
        </div>
      </div>
      <div class="item-input">
        <div class="label"><span class="red">*</span>设备描述：</div>
        <el-input placeholder="请输入设备描述" v-model="formData.unit_des" class="mini"></el-input>
      </div>
    </div>

    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit">确 定</el-button>
      <el-button @click="close">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { getDSSelect, addDevice, updDevice } from '@/api/device'
export default {
  name: 'addProject',
  props: {
    loadChange: Function,
  },
  data() {
    return {
      isShow: false, // 是否打开弹窗
      typeOptions: [
        { id: 1, name: '摄像头' },
        { id: 2, name: '单兵' },
        { id: 3, name: '手环' },
        { id: 4, name: '气体检测' },
        { id: 5, name: '环境检测' },
        { id: 6, name: '人脸设备' },
      ],
      options: [],
      formData: {
        unit_name: '', // 设备名称
        unit_member: '', // 设备编号
        unit_code: '', // 设备验证码、摄像头需要
        unit_type: '', // 设备类型
        unit_aisle: null, // 通道号、摄像头需要
        unit_des: '', // 设备描述
        unit_camera: '', // 设备类别、摄像头、单兵、气体、环境需要
        unit_status: '1', // 显示 or 隐藏
      },
      toastData: {
        unit_name: '请输入设备名称', // 设备名称
        unit_member: '请输入设备编号', // 设备编号
        unit_type: '请选择设备类型', // 设备类型
        unit_status: '请选择设备状态', // 显示 or 隐藏
        unit_des: '请输入设备描述', // 设备描述
      },
      isEdit: false,
    }
  },
  methods: {
    open: function (data) {
      this.isShow = true
      this.isEdit = false
      if (data) {
        this.isEdit = true
        delete data.unit_addtime
        delete data.unit_camera_id
        delete data.unit_online
        data.unit_status = String(data.unit_status)
        data.unit_camera = Number(data.unit_camera)
        this.getChangeType(data.unit_type, data)
      }
    },
    close: function () {
      this.isShow = false
      this.clearData()
    },
    submit: function() {
      for (const key in this.toastData) {
        if (!this.formData[key]) {
          this.$toast(this.toastData[key])
          return false
        }
      }
      // console.log(this.formData)
      !this.isEdit ? this.saveDevice() : this.editDevice()
    },
    saveDevice: function() {
      addDevice(this.formData).then(res => {
        this.$toast(res.message)

        if (res.status === 200) {
          this.close()
          this.loadChange && this.loadChange()
        }
      })
    },
    editDevice: function() {
      updDevice(this.formData).then(res => {
        this.$toast(res.message)

        if (res.status === 200) {
          this.close()
          this.loadChange && this.loadChange()
        }
      })
    },
    getChangeType: function(index, data) {
      this.formData.unit_camera = ''
      this.formData.unit_aisle = null
      this.formData.unit_code = ''
      delete this.toastData.unit_camera
      delete this.toastData.unit_aisle
      delete this.toastData.unit_code

      if (index === 1) {
        this.toastData.unit_aisle = '请输入通道号'
        this.toastData.unit_code = '请输入设备验证码'
      }
      getDSSelect(index).then(res => {
        const { result = [] } = res
        this.options = result

        this.options.length && (
          this.toastData.unit_camera = '请输入设备类别'
        )

        if (data) {
          this.formData = data
        }
      })
    },

    clearData: function() {
      for (const k in this.formData) {
        this.formData[k] = ''
      }
      this.options = []
    },
  },
}
</script>

<style lang="stylus" scoped></style>
