<template>
  <el-dialog
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :destroy-on-close="true"
    :show-close="false"
    width="50%"
    title="预警值"
    :visible.sync="isShow"
  >
    <div class="all-input">
      <div class="item-input">
        <div style="width: 120px;" class="label"><span class="red">*</span>氧气正常值：</div>
        <span class="tag">最小值</span>
        <el-input-number
          class="mini z-tag"
          v-model="formData.o2_min"
          controls-position="right"
          :min="0"
        ></el-input-number>
      </div>
      <div style="margin-left: -7%" class="item-input">
        <div class="label"></div>
        <span class="tag">最大值</span>
        <el-input-number
          class="mini z-tag"
          v-model="formData.o2_max"
          controls-position="right"
          :min="0"
        ></el-input-number>
      </div>
      <div class="item-input">
        <div style="width: 120px;" class="label"><span class="red">*</span>可燃气体正常值：</div>
        <span class="tag">最小值</span>
        <el-input-number
          class="mini z-tag"
          v-model="formData.lel_min"
          controls-position="right"
          :min="0"
        ></el-input-number>
      </div>
      <div style="margin-left: -7%" class="item-input">
        <div class="label"></div>
        <span class="tag">最大值</span>
        <el-input-number
          class="mini z-tag"
          v-model="formData.lel_max"
          controls-position="right"
          :min="0"
        ></el-input-number>
      </div>
      <div class="item-input">
        <div style="width: 120px;" class="label"><span class="red">*</span>硫化氢正常值：</div>
        <span class="tag">最小值</span>
        <el-input-number
          class="mini z-tag"
          v-model="formData.h2s_min"
          controls-position="right"
          :min="0"
        ></el-input-number>
      </div>
      <div style="margin-left: -7%" class="item-input">
        <div  class="label"></div>
        <span class="tag">最大值</span>
        <el-input-number
          class="mini z-tag"
          v-model="formData.h2s_max"
          controls-position="right"
          :min="0"
        ></el-input-number>
      </div>
      <div class="item-input">
        <div style="width: 120px;" class="label"><span class="red">*</span>一氧化碳正常值：</div>
        <span class="tag">最小值</span>
        <el-input-number
          class="mini z-tag"
          v-model="formData.co_min"
          controls-position="right"
          :min="0"
        ></el-input-number>
      </div>
      <div style="margin-left: -7%" class="item-input">
        <div class="label"></div>
        <span class="tag">最大值</span>
        <el-input-number
          class="mini z-tag"
          v-model="formData.co_max"
          controls-position="right"
          :min="0"
        ></el-input-number>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit">确 定</el-button>
      <el-button @click="close">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { getGasWarn, updGasWarn } from '@/api/device'
export default {
  name: 'gasWarningValue',
  props: {
    id: {
      type: [String, Number],
      default() {
        return ''
      },
    },
  },
  data() {
    return {
      isShow: true, // 是否打开弹窗
      formData: {
        o2_max: '',
        o2_min: '',
        co_max: '',
        co_min: '',
        h2s_max: '',
        h2s_min: '',
        lel_max: '',
        lel_min: '',
        unit_id: '',
      },
      toastData: {
        o2_max: '请输入氧气最大值',
        o2_min: '请输入氧气最小值',
        co_max: '请输入二氧化氮最大值',
        co_min: '请输入二氧化氮最小值',
        h2s_max: '请输入二氧化硫最大值',
        h2s_min: '请输入二氧化硫最小值',
        lel_max: '请输入可燃气体最大值',
        lel_min: '请输入可燃气体最小值',
      },
    }
  },
  created() {
    this._loadData()
  },
  methods: {
    _loadData: function() {
      getGasWarn(this.id).then(res => {
        // console.log(res, '：：：：000')
        const { result } = res
        delete result.id
        result.data && (this.formData = result.data)
      })
    },
    close: function (isLoad) {
      this.isShow = false
      this.$emit('close')
    },
    submit: function () {
      // for (const key in this.toastData) {
      //   if (!this.formData[key]) {
      //     this.$toast(this.toastData[key])
      //     return false
      //   }
      // }
      this.formData.unit_id = this.id
      updGasWarn(this.formData).then(res => {
        this.$toast(res.message)

        if (res.status === 200) {
          this.$emit('close')
        }
      })
    },
  },
}
</script>

<style lang="stylus" scoped>
.tag {
  // border-radius: 4px;
  border: 1px solid #DCDFE6;
  box-sizing: border-box;
  height: 40px;
  line-height: 40px;
  border-right: none;
  padding: 0 10px;
  border-radius: 4px 0 0 4px;
  margin-top: 1px;
  white-space nowrap;
  overflow hidden
  text-overflow ellipsis
}
.label {
  white-space nowrap;
  overflow hidden
  text-overflow ellipsis
}
</style>
