<template>
  <el-dialog
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :destroy-on-close="true"
    :show-close="true"
    @close="onCallbackClose"
    width="48%"
    title="定位轨迹"
    :visible.sync="isShow"
    append-to-body
  >
    <div
      v-if="isSearch"
      style="
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 20px;
      "
    >
      <div style="align-items: center; width: 61%" class="item-search">
        <span class="key blod">时间范围：</span>
        <el-date-picker
          class="date-value"
          v-model="date"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          align="right"
        >
        </el-date-picker>
      </div>
      <el-button :loading="isLoad" @click="submit" class="submit" type="primary"
        >搜索</el-button
      >
      <el-button @click="init" class="submit" type="warning">初始化</el-button>
    </div>
    <baidu-map
      v-if="isShowMap"
      style="width: 100%; height: 396px"
      :ak="mapStyle.AK"
      :scroll-wheel-zoom="mapStyle.SCROLLWHEELZOOM"
      :center="center"
      :zoom="zoom"
      @ready="handler"
    >
      <map-marker
        v-for="(item, index) in markers"
        :key="index"
        :position="item | changePos"
        :icon="item.icon || mapStyle.MARKERICON(item)"
        @click="getMarker(item)"
      >
        <map-label
          :content="item.name"
          :labelStyle="mapStyle.LABELSTYLE"
          :offset="mapStyle.LABELOFFSET"
        ></map-label>
      </map-marker>

      <map-polyline
        :path="poylines"
        stroke-color="blue"
        :stroke-opacity="0.5"
        :stroke-weight="2"
      ></map-polyline>

      <map-info-window
        :position="info.item | changePos"
        @close="isShowInfo = false"
        @open="isShowInfo = true"
        :show="isShowInfo"
        title="设备信息"
      >
        <div style="margin-top: 20px; font-size: 14px; color: #a4a8ac">
          <span style="color: #515a6e; font-size: 14px">单兵名称：</span
          >{{ info.name }}
        </div>
        <div style="font-size: 14px; color: #a4a8ac">
          <span style="color: #515a6e; font-size: 14px">设备编号：</span
          >{{ info.num }}
        </div>
        <div style="font-size: 14px; color: #a4a8ac">
          <span style="color: #515a6e; font-size: 14px">单兵位置：</span
          >{{ info.address }}
        </div>
      </map-info-window>
    </baidu-map>
  </el-dialog>
</template>

<script>
import BaiduMap from 'vue-baidu-map/components/map/Map' // 地图组件
import MapMarker from 'vue-baidu-map/components/overlays/Marker' // 地图点
import MapPolyline from 'vue-baidu-map/components/overlays/Polyline'
import MapLabel from 'vue-baidu-map/components/overlays/Label' // 地图点 标签
import MapInfoWindow from 'vue-baidu-map/components/overlays/InfoWindow' // 信息窗口
import { MAP_STYLE } from '@/utils/configMap'
import { getDeviceLine } from '@/api/monitor'
import { getIntLoca, getHandLoca } from '@/api/device'
import { dateMap } from '@/utils/util'

export default {
  name: 'showLocation',
  components: {
    BaiduMap,
    MapMarker,
    MapPolyline,
    MapLabel,
    MapInfoWindow,
  },
  props: {
    id: {
      type: [String, Number],
      default() {
        return ''
      },
    },
    isSearch: {
      type: Boolean,
      default() {
        return false
      },
    },
  },
  data() {
    return {
      mapStyle: MAP_STYLE,
      isShow: true,
      isShowMap: false,
      isShowInfo: false,
      isLoad: false,
      center: {}, // 中心点
      zoom: 15, // 级别
      address: '', // 地址
      current: {}, // 当前Ponit点
      BMap: {}, // 地图实例
      map: {}, // 地图组件
      date: [],
      markers: [],
      poylines: [],
      info: {
        item: {
          mlat: '',
          mlng: '',
        },
      },
    }
  },
  created() {
    this._loadData()
  },
  methods: {
    _loadData: function() {
      if (this.isSearch) {
        getIntLoca(this.id).then(res => {
          const { result } = res
          this.markers = result
          this.isShowMap = true
        })
      } else {
        getHandLoca(this.id).then(res => {
          const { result } = res
          const list = result.list
          this.poylines = []
          this.markers = []
          this.isShowMap = true
          if (result.list.length >= 2) {
            result.list.forEach(s => {
              this.poylines.push({
                lng: Number(s.lon),
                lat: Number(s.lat),
              })
            })
            list.push({
              loca: this.poylines[0],
              icon: {
                url: require('@/assets/map/start.png'),
                size: { width: 38, height: 70 },
              },
              name: '开始位置',
            })
            list.push({
              loca: this.poylines[this.poylines.length - 1],
              icon: {
                url: require('@/assets/map/end.png'),
                size: { width: 38, height: 70 },
              },
              name: '结束位置',
            })
          }
          this.markers = list
        })
      }
    },
    getPyline: function(data) {
      getDeviceLine(data).then(res => {
        const { result } = res
        const { tracks } = result
        this.poylines = []
        this.isLoad = false
        if (!tracks) {
          this.$toast('暂无轨迹')
          return false
        }

        // eslint-disable-next-line no-unused-expressions
        tracks.length &&
          (this.isShowMap = false,
          tracks.forEach(s => {
            this.poylines.push({
              lng: Number(s.mlng),
              lat: Number(s.mlat),
            })
          }),
          this.markers = [
            {
              loca: this.poylines[0],
              icon: {
                url: require('@/assets/map/start.png'),
                size: { width: 38, height: 70 },
              },
              name: '开始位置',
            },
            {
              loca: this.poylines[this.poylines.length - 1],
              icon: {
                url: require('@/assets/map/end.png'),
                size: { width: 38, height: 70 },
              },
              name: '结束位置',
            },
          ],
          this.center = this.poylines[0],
          this.isShowMap = true
          )
      })
    },
    init: function() {
      this.poylines = []
      this.date = []
      this.isShowMap = false
      this._loadData()
    },
    submit: function () {
      if (this.date.length) {
        const time = dateMap(this.date)
        const data = {
          unit_member: this.id,
          time_at: time[0],
          time_end: time[1],
        }
        this.isLoad = true
        this.getPyline(data)
      }
    },
    getMarker: function(item) {
      console.log(item)
      this.info.item = item
      this.info.num = item.imei || item.id
      this.info.address = item.ps || item.address
      this.info.name = item.name
      this.isShowInfo = true
    },
    // 初始化地图
    handler: function ({ BMap, map }) {
      this.BMap = BMap
      this.map = map
      let lng = '120.514466'
      let lat = '30.319445'
      if (this.markers[0]) {
        lng = Number(this.markers[0].mlng || this.markers[0].lon)
        lat = Number(this.markers[0].mlat || this.markers[0].lat)
      }

      this.center = {
        lng: Number(lng),
        lat: Number(lat),
      }
      this.zoom = 15
    },
    // 关闭
    onCallbackClose: function() {
      this.isShow = false
      this.$emit('close')
    },
  },
  filters: {
    changePos: function ({ mlat = '', mlng = '', loca = {}, lon = '', lat = '' }) {
      if (loca.lng) return loca
      else if (lon) {
        return { lat: Number(lat), lng: Number(lon) }
      } else {
        if (!mlat && !mlng) return { lat: 0, lng: 0 }
        else return { lat: Number(mlat), lng: Number(mlng) }
      }
    },
  },
}
</script>

<style lang="stylus" scoped>
.search {
  width: 100%;
  margin-bottom: 20px;
}

.item-search {
  width: 100%;
  display: flex;

  // align-items center;
  .address {
    font-size: 10px;
    color: #999;
  }
}
</style>
